<template>
	<div>
		<v-btn color="accent" class="float-left mx-1" elevation="10" bottom rounded raised large @click="uploadMenu = true">
			<v-icon class="mr-2"> mdi-upload </v-icon>{{ $t('explorer.uploadFiles') }}
		</v-btn>

		<v-dialog v-model="uploadMenu" persistent width="500">
			<v-card rounded="xl">
				<v-card-title>
					{{ $t('explorer.uploadFiles') }}
					<v-spacer />
					<v-btn icon @click="uploadMenu = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<i18n path="explorer.uploadLimitation1" tag="p">
						<template #email>
							<v-btn color="accent" tag="link" text x-small href="mailto:contact@oversos.com" target="_blank" class="text-decoration-none">
								{{ $t('auth.email') }}
							</v-btn>
						</template>
					</i18n>

					<p>{{ $t('explorer.uploadFilesHelp1') }}</p>
					<v-sheet color="orange lighten-1" rounded="xl" class="my-4">
						<v-breadcrumbs :items="foldersHistory" large>
							<template #divider>
								<v-icon>mdi-chevron-right</v-icon>
							</template>
							<template #item="{ item }">
								<v-breadcrumbs-item>{{ item.name }}</v-breadcrumbs-item>
							</template>
						</v-breadcrumbs>
					</v-sheet>

					<v-file-input
						v-model="filestoUpload"
						multiple
						:label="$t('explorer.selectFiles')"
						rounded
						hide-details="auto"
						class="pb-3"
						outlined
						prepend-icon=""
						prepend-inner-icon="mdi-paperclip"
					/>
					<template v-if="filestoUpload.length">
						<v-subheader class="text-subtitle-2">
							{{ $t('explorer.readyToUpload') }}
						</v-subheader>
						<p v-for="(file, index) in filestoUpload" :key="index">
							{{ file.name }}
						</p>
					</template>
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn color="secondary" text rounded @click="clearFiles()">
						{{ $t('settings.clear') }}
					</v-btn>
					<v-btn color="primary" :loading="loading" :disabled="loading" text rounded @click="callUploadFiles()">
						{{ $t('settings.upload') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ExplorerUploadMenu',
	data() {
		return {
			uploadMenu: false,
			filestoUpload: [],
		}
	},
	computed: {
		...mapGetters({
			course: 'courses/course',
			loading: 'explorer/loading',
			foldersHistory: 'explorer/foldersHistory',
		}),
	},
	methods: {
		clearFiles() {
			this.filestoUpload = []
		},
		callUploadFiles() {
			this.uploadFiles(this.filestoUpload).then(({ success }) => {
				if (success) {
					this.filestoUpload = []
					this.fetchFolder({
						course: this.course,
						folder: { name: this.foldersHistory[this.foldersHistory.length - 1].name, id: this.foldersHistory[this.foldersHistory.length - 1].id },
					})
				}
				this.uploadMenu = false
			})
		},
		...mapActions('explorer', ['uploadFiles', 'fetchFolder']),
	},
}
</script>
